<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">เพิ่มข้อมูลสินเชื่อ</p>
      <el-row :gutter="30">
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item label="นำเสนอสินเชื่อไปยังระบบ">
                <el-checkbox
                  :disabled="channelStatus"
                  v-model="formData.sendToCH"
                  >ระบบ Channel Integration</el-checkbox
                >
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="ประเภทของสินเชื่อ" prop="interestType">
                <el-radio-group
                  @change="checkedSelected"
                  v-model="formData.interestType"
                >
                  <el-radio :label="1">Working Capital</el-radio>
                  <el-radio :label="2">Loan (สินเชื่อระยะกลาง-ยาว)</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-row :gutter="30">
            <el-col :span="24" :md="12">
              <el-form-item label="Product Program Name" prop="creditName">
                <el-select
                  id="formDataCreditName"
                  v-model="formData.creditName"
                  filterable
                  clearable
                  placeholder="เลือก Product Program Name"
                  @change="selectProductProgram"
                  @clear="formData.productProgramName = ''"
                >
                  <el-option
                    v-for="item in optionsProductName"
                    :key="item.pptitle"
                    :label="item.pptitle"
                    :value="item.pptitle"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="Product Program Code"
                prop="productProgramName"
              >
                <el-input
                  disabled
                  id="formDataProductProgramCode"
                  v-model="formData.productProgramName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item
                label="ระยะเวลาอนุมัติวงเงิน"
                prop="date"
                class="is-required"
              >
                <el-date-picker
                  id="formDataDate"
                  class="custom-date-picker"
                  v-model="formData.date"
                  type="daterange"
                  start-placeholder="วันที่เริ่ม"
                  range-separator="ถึง"
                  end-placeholder="วันที่สิ้นสุด"
                  @change="changeDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="12">
              <el-form-item label="รูปภาพ">
                <div class="box-addImg">
                  <el-upload
                    action="#"
                    :auto-upload="false"
                    :on-change="
                      (file, fileList) => uploadDocuments(file, fileList)
                    "
                    :on-remove="handleRemoveDocuments"
                    accept="image/jpeg,image,image/png"
                    :limit="1"
                    :on-exceed="handleExceed"
                    list-type="picture"
                  >
                    <el-button type="primary" class="font-16"
                      >แนบไฟล์</el-button
                    >
                    <p
                      class="text-left mg-0 text-grey"
                      style="margin-top: -8px !important"
                    >
                      Max file size: 25MB
                    </p>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" :md="12">
          <el-row :gutter="10"> </el-row>
        </el-col>
        <el-col :span="24">
          <div>
            <el-form-item class="custom-editor" prop="titleOne">
              <div class="box-name-editor">
                <el-input
                  id="titleOne"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleOne"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusOne"
                  class="switch-show-close"
                  v-model="formData.statusOne"
                  :active-text="formData.statusOne == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`attributeProductService`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleTwo">
              <div class="box-name-editor">
                <el-input
                  id="titleTwo"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleTwo"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusTwo"
                  class="switch-show-close"
                  v-model="formData.statusTwo"
                  :active-text="formData.statusTwo == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`taxOrIncrease`"
              :pageName="`credit`"
            />

            <el-form-item class="custom-editor" prop="titleThree">
              <div class="box-name-editor">
                <el-input
                  id="titleThree"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleThree"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusThree"
                  class="switch-show-close"
                  v-model="formData.statusThree"
                  :active-text="formData.statusThree == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`limitMaximum`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleFour">
              <div class="box-name-editor">
                <el-input
                  id="titleFour"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleFour"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusFour"
                  class="switch-show-close"
                  v-model="formData.statusFour"
                  :active-text="formData.statusFour == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`guarantee`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleFive">
              <div class="box-name-editor">
                <el-input
                  id="titleFive"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleFive"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusFive"
                  class="switch-show-close"
                  v-model="formData.statusFive"
                  :active-text="formData.statusFive == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`property`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleSix">
              <div class="box-name-editor">
                <el-input
                  id="titleSix"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleSix"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusSix"
                  class="switch-show-close"
                  v-model="formData.statusSix"
                  :active-text="formData.statusSix == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
              <QuillTextEditor
                :data="formData"
                :name="`condition`"
                :pageName="`credit`"
              />
            </el-form-item>
            <el-form-item class="custom-editor" prop="titleSeven">
              <div class="box-name-editor">
                <el-input
                  id="titleSeven"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleSeven"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusSeven"
                  class="switch-show-close"
                  v-model="formData.statusSeven"
                  :active-text="formData.statusSeven == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`description`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleEight">
              <div class="box-name-editor">
                <el-input
                  id="titleEight"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleEight"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusEight"
                  class="switch-show-close"
                  v-model="formData.statusEight"
                  :active-text="formData.statusEight == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`detailsEight`"
              :pageName="`credit`"
            />
            <el-form-item class="custom-editor" prop="titleNine">
              <div class="box-name-editor">
                <el-input
                  id="titleNine"
                  placeholder="กรุณากรอกชื่อหัวข้อ"
                  v-model="formData.titleNine"
                  clearable
                >
                </el-input>
                <el-switch
                  id="statusNine"
                  class="switch-show-close"
                  v-model="formData.statusNine"
                  :active-text="formData.statusNine == true ? 'แสดง' : 'ซ่อน'"
                >
                </el-switch>
              </div>
            </el-form-item>
            <QuillTextEditor
              :data="formData"
              :name="`detailsNine`"
              :pageName="`credit`"
            />
          </div>
        </el-col>
      </el-row>
      <div class="is-flex js-end">
        <router-link :to="`/manage/credit`">
          <el-button class="font-16 btn-cancle"> ยกเลิก</el-button>
        </router-link>
        <el-button
          type="primary"
          class="font-16"
          @click="$emit('creditAdd')"
          :loading="loadingSave"
          >บันทึก</el-button
        >
      </div>
    </el-card>
  </div>
</template>
<script>
//quill editor
import QuillTextEditor from "@/components/editor/QuillTextEditor";
import { mapState } from "vuex";
import { HTTP } from "@/service/axios";

export default {
  components: { QuillTextEditor },
  props: ["formData", "loadingSave", "optionsMajor", "optionsProductName"],
  mounted() {
    this.chanelStatusSend();
    this.fetchStatusChannel();
  },
  data() {
    return {
      fileList: [],
      sizeLarge: null,
      channelStatus: false,
      statusChannel: null,
    };
  },
  methods: {
    fetchStatusChannel() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get("/ch/credit/show")
        .then((res) => {
          this.channelStatus = res.data.disable;
          this.formData.sendToCH = res.data.send;
        })
        .catch((err) => {
          console.log("Error Fetch Status Channel", err.response.data);
        });
    },
    checkedSelected() {
      if (this.statusChannel != undefined && this.statusChannel != null) {
        if (this.statusChannel.success == false) {
          if (this.formData.interestType == 1) {
            if (this.statusChannel.obj.hasWorkingCap == true) {
              this.channelStatus = true;
              this.formData.sendToCH = false;
              //   this.$message({
              //     message: "สืนเชื่อ Working Cap มีการเลือกส่งไปยัง Channel Integration แล้ว",
              //     type: "warning",
              //     duration: 4000,
              // });
            } else if (this.statusChannel.obj.hasWorkingCap == false) {
              this.channelStatus = false;
            }
          } else if (this.formData.interestType == 2) {
            if (this.statusChannel.obj.hasLoan == true) {
              this.channelStatus = true;
              this.formData.sendToCH = false;
              //   this.$message({
              //     message: "สืนเชื่อ Loan มีการเลือกส่งไปยัง Channel Integration แล้ว",
              //     type: "warning",
              //     duration: 4000,
              // });
            } else if (this.statusChannel.obj.hasLoan == false) {
              this.channelStatus = false;
            }
          }
        }
      }
    },
    changeDate() {
      this.formData.creditStart = this.formData.date[0];
      this.formData.creditEnd = this.formData.date[1];
    },

    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");

      if (
        typeThis[1] == "jpeg" ||
        typeThis[1] == "png" ||
        typeThis[1] == "jpg"
      ) {
        if (file.size < 25000000) {
          let obj = {
            name: file.name,
            file: file,
          };
          this.formData.imageFile.push(obj);
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25mb",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "กรุณาเลือกไฟล์นามสกุลเป็น JPEG หรือ PNG เท่านั้น",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file) {
      let index = this.formData.imageFile.findIndex(
        (f) => f.file.uid == file.uid
      );
      this.formData.imageFile.splice(index, 1);
    },

    addNameCredit() {
      this.formData.nameCredit.push({ name: "" });
    },
    handleExceed(files, fileList) {
      this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 1 ไฟล์`);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    selectProductProgram(item) {
      let findArr = this.optionsProductName.find((e) => e.pptitle == item);
      if (typeof findArr != "undefined") {
        this.formData.productProgramName = findArr.ppcode;
      }
    },
    chanelStatusSend() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get("/ch/credit/add")
        .then((res) => {
          this.statusChannel = res.data;
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
  },
  computed: {
    ...mapState({
      token: (state) => state._token,
    }),
  },
};
</script>
