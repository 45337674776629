<template>
  <DefaultTemplate>
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/credit' }"
            >จัดการข้อมูลสินเชื่อ</el-breadcrumb-item
          >
          <el-breadcrumb-item>เพิ่มข้อมูลสินเชื่อ</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">เพิ่มข้อมูลสินเชื่อ</p>
      </div>
    </div>
    <el-form
      label-position="top"
      :model="form"
      class="set-form"
      :rules="rules"
      ref="form"
    >
      <Step1 @stepNow="formDataOut" :formData="form" />
      <Step2
        v-if="stepTwo"
        :formData="form"
        :optionsMajor="optionsMajor"
        :optionsProductName="optionsProductName"
        @creditAdd="creditAdd"
        :loadingSave="loadingSave"
      />
    </el-form>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/credit/Step1";
import Step2 from "@/components/credit/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },
  data() {
    var validGrade = (rule, value, callback) => {
      if (this.form.grade.length == 0) {
        callback(new Error("กรุณาเลือกเกรด"));
      } else {
        callback();
      }
    };
    var validDate = (rule, value, callback) => {
      if (this.form.creditStart == "" && this.form.creditEnd == "") {
        callback(new Error("กรุณาเลือกวันที่การให้สินเชื่อ"));
      } else {
        callback();
      }
    };
    var validProfit = (rule, value, callback) => {
      if (value == 0) {
        callback(new Error("กรุณากรอกความสามารถในการชำระดอกเบี้ย"));
      } else {
        callback();
      }
    };
    var validtitleOne = (rule, value, callback) => {
      if (this.form.statusOne && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleTwo = (rule, value, callback) => {
      if (this.form.statusTwo && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleThree = (rule, value, callback) => {
      if (this.form.statusThree && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFour = (rule, value, callback) => {
      if (this.form.statusFour && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFive = (rule, value, callback) => {
      if (this.form.statusFive && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSix = (rule, value, callback) => {
      if (this.form.statusSix && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSeven = (rule, value, callback) => {
      if (this.form.statusSeven && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleEight = (rule, value, callback) => {
      if (this.form.statusEight && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleNine = (rule, value, callback) => {
      if (this.form.statusNine && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    return {
      loadingSave: false,
      stepTwo: false,
      form: {
        sendToCH:false,
        regisDate:"",
        operatorType:[],
        cateIndustryType: [],
        projectTERAK: [],
        creditProductId: "",
        creditName: "",
        creditSizeS: false,
        creditSizeM: false,
        creditSizeL: false,
        interestType: null, // ประเภทสินเชื่อ
        abilityProfit: 0, // ความสามารถ
        grade: [], //เกรด
        date: [],
        creditStart: "",
        creditEnd: "",
        attributeProductService: "",
        taxOrIncrease: "",
        limitMaximum: "",
        guarantee: "",
        property: "",
        condition: "",
        description: "",
        imageFile: [],
        image: "",
        titleOne: "ลักษณะผลิตภัณฑ์",
        titleTwo: "อัตราดอกเบี้ย/ค่าธรรมเนียม (ต่อปี)",
        titleThree: "วงเงินสูงสุด",
        titleFour: "หลักประกัน",
        titleFive: "คุณสมบัติผู้กู้",
        titleSix: "เงื่อนไข",
        titleSeven: "รายละเอียดเพิ่มเติม",
        titleEight: "รายละเอียดเพิ่มเติม",
        titleNine: "รายละเอียดเพิ่มเติม",
        statusOne: true,
        statusTwo: true,
        statusThree: true,
        statusFour: true,
        statusFive: true,
        statusSix: true,
        statusSeven: true,
        statusEight: false,
        statusNine: false,

        detailsEight: "",
        detailsNine: "",

        majorMinor: "",
        productProgramName: "",
      },
      rules: {
        gradeValid: [{ validator: validGrade, trigger: ["blur", "change"] }],
        titleOne: [{ validator: validtitleOne, trigger: ["blur", "change"] }],
        titleTwo: [{ validator: validtitleTwo, trigger: ["blur", "change"] }],
        titleThree: [{ validator: validtitleThree, trigger: ["blur", "change"] }],
        titleFour: [{ validator: validtitleFour, trigger: ["blur", "change"] }],
        titleFive: [{ validator: validtitleFive, trigger: ["blur", "change"] }],
        titleSix: [{ validator: validtitleSix, trigger: ["blur", "change"] }],
        titleSeven: [{ validator: validtitleSeven, trigger: ["blur", "change"] }],
        titleEight: [{ validator: validtitleEight, trigger: ["blur", "change"] }],
        titleNine: [{ validator: validtitleNine, trigger: ["blur", "change"] }],
        cateIndustryType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทอุตสาหกรรม",
            trigger: "change",
          },
        ],
        majorMinor: [
          {
            required: true,
            message: "กรุณาเลือก Major - Minor",
            trigger: "change",
          },
        ],
        productProgramName: [
          {
            required: true,
            message: "กรุณาเลือก Product Program Code",
            trigger: "change",
          },
        ],
        projectTERAK: [
          {
            required: true,
            message: "กรุณาเลือกโครงการจากระบบ TERAK",
            trigger: "change",
          },
        ],
        creditProductId: [
          {
            required: true,
            message: "กรุณากรอก Credit Product ID",
            trigger: ["blur", "change"],
          },
        ],
        interestType: [
          {
            required: true,
            message: "กรุณาเลือกประเภทของสินเชื่อ",
            trigger: "change",
          },
        ],
        abilityProfit: [
          {
            required: true,
            message: "กรุณากรอกความสามารถในการชำระดอกเบี้ย",
            trigger: "blur",
          },
          { validator: validProfit, trigger: "blur" },
        ],
        creditName: [
          {
            required: true,
            message: "กรุณาเลือก Product Program Name",
            trigger: ["blur", "change"],
          },
        ],
        date: [{ validator: validDate, trigger: ["blur", "change"] }],
      },
      optionsMajor: [],
      optionsProductName: [],
    };
  },
  mounted() {
    this.getMasterDataMajor();
    this.getProductName();
  },
  methods: {
    getMasterDataMajor() {
      HTTP.get(`crm/credit/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsMajor = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getProductName() {
      HTTP.get(`crm/product/program/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsProductName = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    formDataOut(data) {
      if (data.creditSizeS || data.creditSizeM || data.creditSizeL) {
        this.form = data;
        if (this.form.grade.length == 0) {
          this.form = data;
          // if (
          //   this.form.creditSizeS ||
          //   this.form.creditSizeM
          // ) {
          //   if (this.form.interestType != 1 && this.form.interestType != 2) {
          //     this.form.interestType = "";
          //   }
          // } else if (this.form.creditSizeL) {
          //   if (this.form.interestType != 3 && this.form.interestType != 4) {
          //     this.form.interestType = "";
          //   }
          // }
        }
        this.stepTwo = true;
      } else {
        this.stepTwo = false;
      }
    },
    async creditAdd() {
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;

          let resImg = "";
          if (this.form.imageFile.length > 0) {
            var formDataTERAK = new FormData();
            formDataTERAK.append("file", this.form.imageFile[0].file.raw);
            var token = jwt.sign(
              { system: "CFOE2021" },
              "Y2weQEzYjje3LRDiwAUiX1U2r",
              { expiresIn: "5m" }
            );
            TERAK.defaults.headers.common.Authorization = token;
            const res = await TERAK.post(`cfoe/file`, formDataTERAK);
            if (res.data.success) {
              resImg = res.data.filename;
            }
          }
          let grade = this.form.grade.findIndex((a) => a === 999);
          let cateIndustryType = this.form.cateIndustryType.findIndex(
            (a) => a === 999
          );
          let projectTERAK = this.form.projectTERAK.findIndex((a) => a === 999);

          if (grade > -1) {
            this.form.grade.splice(grade, 1);
          }
          if (cateIndustryType > -1) {
            this.form.cateIndustryType.splice(cateIndustryType, 1);
          }
          if (projectTERAK > -1) {
            this.form.projectTERAK.splice(projectTERAK, 1);
          }

          let forGrade = [];
          let forOperatorType = [];
          let forCateIndustryType = [];
          let forProjectTERAK = [];

          this.form.grade.forEach((row) => {
            forGrade.push(row.toString());
          });
          this.form.operatorType.forEach((row) => {
            forOperatorType.push(row.toString());
          });
           this.form.cateIndustryType.forEach((row) => {
            forCateIndustryType.push(row.toString());
          });
          this.form.projectTERAK.forEach((row) => {
            forProjectTERAK.push(row.toString());
          });

          let setForm = {
            sendToCH: this.form.sendToCH,
            regisDate: this.form.regisDate == '' ? 0 : this.form.regisDate,
            operatorType:forOperatorType,
            creditName: this.form.creditName,
            creditSizeS: this.form.creditSizeS,
            creditSizeM: this.form.creditSizeM,
            creditSizeL: this.form.creditSizeL,
            interestType: this.form.interestType,
            // abilityProfit: this.form.abilityProfit,
            creditStart: this.form.creditStart,
            creditEnd: this.form.creditEnd,
            attributeProductService: this.form.attributeProductService,
            taxOrIncrease: this.form.taxOrIncrease,
            limitMaximum: this.form.limitMaximum,
            guarantee: this.form.guarantee,
            condition: this.form.condition,
            property: this.form.property,
            description: this.form.description,
            cateIndustryType: forCateIndustryType,
            projectTERAK: forProjectTERAK,
            grade: forGrade,
            // creditProductId: this.form.creditProductId,
            image: resImg,
            titleOne: this.form.titleOne,
            titleTwo: this.form.titleTwo,
            titleThree: this.form.titleThree,
            titleFour: this.form.titleFour,
            titleFive: this.form.titleFive,
            titleSix: this.form.titleSix,
            titleSeven: this.form.titleSeven,
            titleEight: this.form.titleEight,
            titleNine: this.form.titleNine,
            statusOne: this.form.statusOne,
            statusTwo: this.form.statusTwo,
            statusThree: this.form.statusThree,
            statusFour: this.form.statusFour,
            statusFive: this.form.statusFive,
            statusSix: this.form.statusSix,
            statusSeven: this.form.statusSeven,
            statusEight: this.form.statusEight,
            statusNine: this.form.statusNine,
            detailsEight: this.form.detailsEight,
            detailsNine: this.form.detailsNine,

            majorMinor: this.form.majorMinor,
            productProgramName: this.form.productProgramName,
          };

          HTTP.post(`credit/create`, setForm)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "เพิ่มข้อมูลสินเชื่อสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/credit`);
              } else {
                this.loadingSave = false;
                if (res.data.status == 204) {
                  this.$message({
                    message: "ชื่อข้อมูลสินเชื่อซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                } else if (res.data.status == 403) {
                  this.$store.commit("SET_PAGE_ACTIVE", null);
                  this.$router.push("/not-allowed");
                }
              }
            })
            .catch((e) => {
              this.loadingSave = false;

              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
  },
};
</script>
